import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { AuthService } from '@app/modules/auth/services/auth.service'
import { Workspace } from '@app/modules/workspaces/interfaces/workspace.interface'
import { ToastrService } from '@app/services/toastr.service'

@Component({
  selector: 'ngx-user-dropdown',
  templateUrl: './user-dropdown.component.html',
  styleUrls: ['./user-dropdown.component.scss']
})
export class UserDropdownComponent implements OnInit {
  user
  avatarHasError = false
  defaultAvatar = 'https://res.cloudinary.com/contactless/image/upload/v1594285967/public/Avatar_default_big_2x.png'
  showBillingTab = true;
  constructor(private auth: AuthService, private router: Router, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.auth.currentWorkspaceChange.subscribe((workspace) => {
      if(workspace) this.showBillingTab =true
    })

    this.showBillingTab = (this.auth.currentWorkspace && (this.auth.user.is_admin || this.auth.currentParentWorkspace))
    this.user = this.auth.user
  }

  get avatar() {
    if (!this.auth.user.profile_picture) {
      return this.auth.user.full_name && this.auth.user.full_name.split(' ').length > 1
        ? this.auth.user.full_name.split(' ')[0][0] + this.auth.user.full_name.split(' ')[1][0]
        : this.auth.user.email[0] + this.auth.user.email[1]
    } else {
      return this.auth.user.profile_picture
    }
  }

  get name() {
    if (this.user.full_name) {
      return this.user.full_name
    } else {
      return this.user.email
    }
  }

  navigateToBilling() {
    const parentWorkspaces: Workspace[] = this.auth.workspaces.filter((workspace) => workspace.parent === null)
    if (this.auth.currentWorkspace && (this.auth.user.is_admin || this.auth.currentParentWorkspace)) {
      this.router.navigate(['/billing'])
    } else if (parentWorkspaces.length === 1) {
      this.auth.currentParentWorkspace = parentWorkspaces[0]
      this.auth.currentWorkspace = parentWorkspaces[0]
      this.router.navigate(['/billing'])
    } else {
      this.toastr.info('Please set your workspace first', '')
    }
  }

  logout() {
    this.auth.user = null
    this.auth.currentWorkspace = null
    this.auth.currentParentWorkspace = null
    this.auth.workspaces = null
    this.auth.plans = null
    if (!!localStorage.getItem('benchmarkToken')) {
      localStorage.removeItem('benchmarkToken')
    }
    this.router.navigate(['/auth', 'login'])
  }
}
