<div *ngIf="user" class="user-menu d-flex justify-content-end align-items-center mr-16">
  <div
    [ngClass]="{ 'border-radious-when-menu-open opened-menu-box-shadow': t.menuOpen === true }"
    class="profile-wrapper"
    mat-button
    [matMenuTriggerFor]="menu"
    #t="matMenuTrigger"
  >
    <img *ngIf="avatar.startsWith('http')" class="objectfit" [src]="avatar" (error)="avatarHasError = true" />
    <div class="alternative-avatar" *ngIf="!avatar.startsWith('http')">
      <span>
        {{ avatar }}
      </span>
    </div>
    <p class="user-name">{{ (name | slice : 0 : 13) + (name.length > 13 ? '...' : '') }}</p>

    <div *ngIf="t.menuOpen; else elseBlock">
      <mat-icon>keyboard_arrow_up</mat-icon>
    </div>
    <ng-template #elseBlock>
      <mat-icon>keyboard_arrow_down</mat-icon>
    </ng-template>
  </div>

  <mat-menu class="menu-wrapper" #menu="matMenu">
    <button *ngIf="showBillingTab" class="billing" mat-menu-item (click)="navigateToBilling()">
      <div class="dropdown-label">Billing</div>
      <div class="icon"><img src="assets/images/creditcard_icon.svg" alt="" /></div>
    </button>
    <button class="logout border-top" mat-menu-item (click)="logout()">
      <div class="dropdown-label">Sign out</div>
      <div class="icon"><img src="assets/images/signout_icon.svg" alt="" /></div>
    </button>
  </mat-menu>
</div>
